<template>
  <div>
    <b-card class="text-left" title="Visão geral">
      <!-- Totais de usuario e projetos -->
      <b-row class="">
        <b-col cols="8">
          <div class="infos">
            <p>
              Projetos em andamento:
              <strong>{{ item.projectsInProcess }}</strong>
            </p>
            <p>
              Projetos arquivados:
              <strong>{{ item.archivedProjects }}</strong>
            </p>
            <p>
              Usuários ativos: <strong>{{ item.users }}</strong>
            </p>
          </div>
        </b-col>
        <b-col cols="4">
          <b-button @click="$router.push(`/usuarios/dashboard/${member.member_id}`)" variant="success" class="cta"
            >Meu Painel</b-button
          >
          <b-button @click="openModalAction" variant="success" class="cta">+ Adicionar tarefa</b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Filtro">
      <!-- Inputs for date range and toggle button (all in the same row) -->
      <b-row class=" align-items-end d-flex">
        <!-- INTERVALO -->
        <b-col md="3">
          <!-- start_date -->
          <b-form-group label="Data de Início">
            <b-form-input v-model="startDate" type="month" />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <!-- end_date -->
          <b-form-group label="Data de Fim">
            <b-form-input v-model="endDate" type="month" />
          </b-form-group>
        </b-col>

        <!-- Botoes  -->
        <b-col sm="6" md="3">
          <b-button variant="primary" @click="applyDateFilter" class="cta" block> Filtrar </b-button>
        </b-col>

        <b-col sm="6" md="3">
          <b-button variant="primary" @click="getAllPeriod" class="cta" block> Todo Período </b-button>
        </b-col>

        <!-- Toggle button -->
        <!-- Empilhado em telas menores -->
        <!-- <b-col sm="12" class="mt-2">
        <b-button variant="primary" @click="toggleDateFiltering" class="cta" block>
          {{ useDateRangeInputs ? 'Intervalo' : 'Mensal' }}
        </b-button>
      </b-col> -->
      </b-row>
    </b-card>

    <b-card>
      <!--  -->
      <b-col cols="12">
        <b-row class="d-flex justify-content-around">
          <div class="dashboard-item text-center">
            <strong>{{ `R$ ${numberToMonetary(item.projectCosts)}` }}</strong>
            <small>Total de despesas de projeto </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` R$ ${numberToMonetary(item.legalExpenses)}` }}</strong>
            <small>Total de despesas legais (impostos) </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `R$ ${numberToMonetary(item.projectRevenue)}` }}</strong>
            <small>Total de receitas </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `R$ ${numberToMonetary(item.direct_cost)}` }}</strong>
            <small>Custo Direto </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` R$ ${numberToMonetary(item.indirect_cost)}` }}</strong>
            <small>Custo Indireto </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` R$ ${numberToMonetary(item.projectTasksTotalCost)}` }}</strong>
            <small>Custo Total (tarefas) </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` R$ ${numberToMonetary(item.projectTotalCost)}` }}</strong>
            <small>Custo Total (projetos) </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` R$ ${numberToMonetary(item.contributionMargin)}` }}</strong>
            <small>Margem de contribuição </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `R$ ${numberToMonetary(item.officeTotalCost)}` }}</strong>
            <small>Custo total de escritório </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` R$ ${numberToMonetary(item.costDepreciation)}` }}</strong>
            <small>Custo de depreciação </small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ ` R$ ${numberToMonetary(item.realCostOffice)}` }}</strong>
            <small>Custo real do escritório</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{
              `R$ ${
                item.officeTotalCost > 0 ? numberToMonetary(item.officeTotalCost / item.hours + item.costDepreciation) : '0'
              }`
            }}</strong>
            <small>Custo real da hora</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${item.hours}h` }}</strong>
            <small>Total de horas trabalhadas</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{ `${item.profitability}%` }}</strong>
            <small>Lucratividade</small>
          </div>

          <div class="dashboard-item text-center">
            <strong>{{
              `${signalNumber(item.projectResult) == false ? '-' : ''}R$ ${numberToMonetary(
                signalNumber(item.projectResult) == false ? item.projectResult * -1 : item.projectResult
              )}`
            }}</strong>
            <small>Resultado do escritório</small>
          </div>
        </b-row>
      </b-col>

      <Modal @closeModal="closeModal" :open="myTasksModal" />
    </b-card>

    <!-- DASHBOARDS -->

    <b-card v-if="hoursPerEmployee_data.render" class="overflow-scroll-mobile">
      <b-col class="text-center mx-auto w-10 h-50">
        <label>Horas por colaborador</label><br />
        <label>{{ `${item.hours}h 00m` }}</label>
        <ProfitabilityChart :chartData="hoursPerEmployee_data"
      /></b-col>
    </b-card>

    <b-card v-if="costPerProject_data.render" class="overflow-scroll-mobile">
      <b-col class="text-center mx-auto w-10 h-50">
        <label>Custo por projeto</label>
        <ProfitabilityChart :chartData="costPerProject_data"
      /></b-col>
    </b-card>
  </div>
</template>

<script>
import { VBTooltip, BCol, BRow, BCard, BFormGroup, BButton, BFormInput } from 'bootstrap-vue';
import ProfitabilityChart from '@/components/Chart.vue';
import Modal from '@/views/pages/manager/myTasks/Modal.vue';
//import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip
  },
  components: {
    BCol,
    BRow,
    BCard,
    ProfitabilityChart,
    BFormGroup,
    BButton,
    BFormInput,
    Modal
  },
  data: () => ({
    item: {
      projectCosts: 0,
      legalExpenses: 0,
      projectRevenue: 0,
      direct_cost: 0,
      indirect_cost: 0,
      projectTasksTotalCost: 0,
      projectTotalCost: 0,
      contributionMargin: 0,
      officeTotalCost: 0,
      costDepreciation: 0,
      realCostOffice: 0,
      realCostHour: 0,
      hours: 0,
      profitability: 0,
      projectResult: 0,
      projectsInProcess: 0,
      archivedProjects: 0,
      users: 0
    },

    member: {},
    selectedMonth: 'Janeiro',
    selectedYear: 2024,
    months: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    years: [2020, 2021, 2022, 2023, 2024, 2025],

    useDateRangeInputs: false,
    startDate: '',
    endDate: '',
    dispatch_start_date: '',
    dispatch_end_date: '',
    project_name: 'Projeto',
    workspace: { workspace_title: '' },
    myTasksModal: false,
    hoursPerEmployee_data: { render: false },
    costPerProject_data: { render: false }
  }),

  created() {
    this.getCurrentMonth();
    this.updateDates();
    this.getItem();
    this.getMemberInfo();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },

    start_date() {
      const monthIndex = this.months.indexOf(this.selectedMonth) + 1;
      return this.useDateRangeInputs
        ? this.startDate
        : new Date(this.selectedYear, monthIndex - 1, 1).toISOString().split('T')[0];
    },
    end_date() {
      const monthIndex = this.months.indexOf(this.selectedMonth) + 1;
      if (this.useDateRangeInputs) {
        return this.endDate;
      } else {
        const startDate = new Date(this.selectedYear, monthIndex - 1, 1);
        const endDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
        return endDayOfMonth.toISOString().split('T')[0];
      }
    }
  },

  methods: {
    async getItem() {
      this.$store
        .dispatch('getWorkspace', {
          id: this.$store.getters.currentWorkspace.id
        })
        .then(resp => {
          this.workspace = resp;
        })
        .catch(err => {
          console.log(err);
        });
    },
    getCurrentMonth() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth()).padStart(2, '0');
      this.startDate = `${year}-${month}`;
      this.endDate = `${year}-${month}`;

      this.dispatch_start_date = `${year}-${month}`;
      this.dispatch_end_date = `${year}-${month}`;
    },

    async getMemberInfo() {
      this.$store
        .dispatch('getUserMember', {
          id: this.$store.getters.user.id,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(resp => {
          this.member = resp[0];
          console.log(this.member);
        })
        .catch(err => {
          console.log(err);
        });
    },

    async getAll() {
      this.$store
        .dispatch('getDashboard', {
          start_date: this.dispatch_start_date,
          end_date: this.dispatch_end_date,
          workspace_id: this.$store.getters.currentWorkspace.id
        })
        .then(async resp => {
          this.setHoursPerEmployee_data(resp.employeeHours);
          this.setCostPerProject_data(resp.projectsCosts);
          this.item = {
            ...resp.resumeDashboard
          };
        })
        .catch(err => {
          console.log(err);
        });
    },

    async getAllPeriod() {
      const anoAtual = new Date().getFullYear();
      const startDate = new Date(anoAtual, 0, 1);
      const formattedStartDate = startDate.toISOString().split('T')[0];

      const endDate = new Date(anoAtual, 11, 31);
      const formattedEndDate = endDate.toISOString().split('T')[0];

      this.dispatch_start_date = formattedStartDate;
      this.dispatch_end_date = formattedEndDate;

      this.$toast.success('Selecionado todo o periodo.', {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false
      });

      await this.getAll();
    },

    async updateDates() {
      const startDateWithDay = `${this.startDate}-01`;
      const endDateYear = parseInt(this.endDate.substring(0, 4));
      const endDateMonth = parseInt(this.endDate.substring(5, 7));

      const lastDayOfMonth = new Date(endDateYear, endDateMonth, 0).getDate();

      const formattedMonth = endDateMonth < 10 ? `0${endDateMonth}` : endDateMonth;

      const endDateWithLastDay = `${endDateYear}-${formattedMonth}-${lastDayOfMonth}`;

      this.dispatch_start_date = startDateWithDay;
      this.dispatch_end_date = endDateWithLastDay;
      this.getAll();
    },
    padMonth(month) {
      return month.toString().padStart(2, '0');
    },
    getMonthNumber(monthName) {
      const months = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ];
      return months.indexOf(monthName) + 1;
    },

    toggleDateFiltering() {
      this.useDateRangeInputs = !this.useDateRangeInputs;
    },

    applyDateFilter() {
      this.$toast.success('Filtrado por período.', {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false
      });

      this.updateDates();
    },

    async setHoursPerEmployee_data(props) {
      const data = [];
      const date = [];
      props.map(e => {
        data.push(e.hour);
        date.push(e.username);
      });

      this.hoursPerEmployee_data = {
        render: date.length > 0,
        width: '100%',
        height: '300',
        chartOptions: {
          chart: {
            type: 'bar'
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '50px'
            },
            dataLabels: {
              hideOverflowingLabels: true
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            labels: {
              formatter: function (number) {
                return Math.floor(number);
              }
            },
            categories: date
          }
        },
        chartSeries: [
          {
            name: 'Horas',
            data
          }
        ]
      };
    },

    async setCostPerProject_data(props) {
      const data = [];
      const date = [];
      props.map(e => {
        data.push(e.project_cost + e.legalExpenses / 100);
        date.push(e.project_name);
      });

      this.costPerProject_data = {
        render: date.length > 0,
        width: '100%',
        height: 300,
        chartOptions: {
          chart: {
            type: 'bar'
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: true,
              columnWidth: '100px'
            }
          },
          dataLabels: {
            enabled: false
          },
          yaxis: {
            categories: data
          },
          xaxis: {
            labels: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100).toFixed(2).toString().split('.');
                const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                const result = `R$ ${formattedIntegerPart},${decimalPart}`;
                return result;
              }
            },
            categories: date
          },
          tooltip: {
            x: { show: true },
            y: {
              formatter: function (number) {
                if (isNaN(number)) {
                  return 'Invalid input';
                }
                if (number === 0) {
                  return 'R$ 0,00';
                }
                if (number < 100) {
                  const formattedCents = number.toFixed(2).padStart(5, '0');
                  return `0,${formattedCents}`;
                }
                const [integerPart, decimalPart] = (number / 100).toFixed(2).toString().split('.');
                const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                const result = `R$ ${formattedIntegerPart},${decimalPart}`;
                return result;
              }
            }
          }
        },
        chartSeries: [
          {
            name: 'Custo',
            data
          }
        ]
      };
    },

    openModalAction() {
      this.myTasksModal = true;
    },
    closeModal() {
      this.myTasksModal = false;
    }
  }
};
</script>
<style scoped>
.cta {
  width: 100%;
  margin-bottom: 10px;
}
.infos {
  display: flex;
  flex-direction: column;
}
.dashboard-item {
  margin-bottom: 15px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  text-align: start;
  width: 200px;
}

.overflow-scroll-mobile {
  overflow-x: auto;
  white-space: nowrap;
}
</style>
